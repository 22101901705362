













































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab3Q7',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'pH at which the acid-base reaction is no longer in equilibrium',
          value: 'notEquilibrium',
        },
        {
          text: 'pH at which the concentration of the acidic form equals the concentration of the conjugate base form',
          value: 'concentration',
        },
        {
          text: 'pH at which all the molecules are in the neutral acidic form',
          value: 'neutralAcidicForm',
        },
        {text: 'pH of an acidic solution', value: 'acidicSolution'},
        {
          text: 'pH at which all the molecules are in the conjugate base form',
          value: 'conjugateBaseForm',
        },
      ],
      optionsFr: [
        {
          text: 'pH auquel la concentration du forme acide est égale à la concentration du forme de base conjuguée',
          value: 'concentrationFr',
        },
        {
          text: 'pH auquel toutes les molécules sont sous forme de base conjuguée',
          value: 'conjugateBaseFormFr',
        },
        {text: "pH d'unes solution acide", value: 'acidicSolutionFr'},
        {
          text: "pH auquel toutes les molécules song sous forme d'acide neutre",
          value: 'neutralAcidicFormFr',
        },
        {
          text: "pH auquel la réaction acide-base n'est plus à l'équilibre",
          value: 'notEquilibriumFr',
        },
      ],
    };
  },
};
